import React from "react";
import styles from "./header.module.css";
import logo from "../../images/logo.png";
import introImage from "../../images/intro.png";
import Button from "../Button/Button";

const Header = () => {
  return (
    <header className={styles.appHeader}>
      <img src={logo} alt="Deez Guys" className={styles.logo} />
      <div className={styles.container}>
        <div className={styles.intro}>
          <h1>Who the f*%k are DeezGuys?</h1>
          <p>
            Made with <strong>100% love</strong>, this, first gen, 9k generative
            art project focuses on leveraging the essence of an NFT avatar, to
            unify a <strong>community</strong> and create{" "}
            <strong>connected experiences</strong> all the while, allowing
            people to form truly unique personas
          </p>
          <p>Reserving 300 DG’s to share and build the future 🚀</p>
          <div className={styles.buttonContainer}>
            <a
              target="_blank"
              href="https://nft.gamestop.com/collection/deezguys"
            >
              Buy now
            </a>
            <a target="_blank" href="http://discord.gg/uF22mpVVbC">
              Join Discord
            </a>
            {/* <button
              className={styles.button}
              type="button"
              data-hover="Coming soon"
              data-active="I'M ACTIVE"
            >
              <span>Discord</span>
            </button> */}
          </div>
        </div>
        <div className={styles.introImage}>
          <img src={introImage} alt="Smile Guy" className={styles.introImage} />
        </div>
      </div>
    </header>
  );
};

export default Header;
