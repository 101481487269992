import styles from "./app.module.css";
import Header from "../src/components/Header/Header";
import BackgroundContainer from "./components/BackgroundContainer/BackGroundContainer";
import Footer from "../src/components/Footer/Footer";

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <BackgroundContainer bgcolor={"#FE3087"}>
        <div className={styles.twoColContainer}>
          <div className={styles.colOne}>
            <p>
              You ask us, who are DeezGuys.. and our response is, we really
              don't know...... this is for you to decide.
            </p>
            <p>
              As they stand, they are an amalgamation of cultural noise,
              combined into a beautiful and unique shell, a shell, as bespoke as
              it is, sits shoulder to shoulder in a recognizable community. Is
              your DG a representation of you in the online world we live in,
              or, when you take on this digital disguise are you breathing life
              into something brand new that grows beyond the confines of your
              own personality.
            </p>
            <p>
                You create DeezGuys, you build the connected world around them.
            </p>
          </div>
          <div className={styles.colTwo}>
            <h1>You've peaked my interest, What's it all about?</h1>
          </div>
        </div>
        <div className={styles.deezGuysImage} />
      </BackgroundContainer>
      <BackgroundContainer bgcolor={"#30F2FE"}>
        <div className={styles.roadmap}>
          <h1>Roadmap.. Forget that</h1>
          <p>
            Forget a terrible roadmap and set of empty promises. As we said, you
            create DeezGuys and you build the connected world. So, with that in
            mind, we'd rather focus on our community ambitions, that provide the
            framework for how we move forward together, so here goes:
          </p>
          <h1 style={{ marginBottom: "0" }}>1</h1>
          <h2>To be bold and visable</h2>
          <p>
            We want to work with you, the community, to find engaging ways to
            make the art style and Deezguys more iconic and recognisable around
            the world. Let's share opportunities together, to build brands
            through characters, share artwork and push it into the public
            domain. Let's also consider the expansion opportunities of character
            and art, through traditional and digital mediums.
          </p>
          <h1 style={{ marginBottom: "0" }}>2</h1>
          <h2>To bring them to life</h2>
          <p>
            As you build your character persona through art and social, we want
            to explore new ways to play and engage. Let's look at the digital
            ecosy stem and opportunities to expand the reasons for being. We
            have some really exciting ideas around this, so watch this space.
          </p>
          <h1 style={{ marginBottom: "0" }}>3</h1>
          <h2>To reward support</h2>
          <p>
            An obvious one we know, but we want to make sure this is front and
            centre and that we are always introducing super exciting perks, from
            air drops right the way through to a seat at the big table for our
            thought leaders.
          </p>
          <h1 style={{ marginBottom: "0" }}>4</h1>
          <h2>To connect as a community</h2>
          <p>
            Although this project champions the anonymity through DeezGuys, we
            want to drive activations that bring each of our owners closer
            together, Through IRL activities and group development. We all have
            a shared passion here, so let’s band together
          </p>
          {/* <p>
            <strong>
              Note: At 15k followers across our twitter account we will be
              sharing the initial post launch ambitions in the hope that our
              community can help shape the specific next steps. Let's do this
              together!!
            </strong>
          </p> */}
        </div>
        <div className={styles.deezGuysImageLong} />
      </BackgroundContainer>
      <Footer />
    </div>
  );
}

export default App;
