import React from "react";
import logo from "../../images/logo.png";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={logo} alt="Deez Guys" className={styles.footerImage} />
    </div>
  );
};

export default Footer;
